import React, { useEffect } from "react";
import {
  Divider,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import clsx from 'clsx';

import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import TransformIcon from "@material-ui/icons/Transform";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PersonIcon from '@material-ui/icons/Person';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SettingsIcon from '@material-ui/icons/Settings';


import { useLocation } from "@reach/router";

interface SideLinksProps {
  DisplayName: string | null;
  Url: string;
  ImageUrl: JSX.Element;
}

interface Prop {
  isDrawerOpen: Boolean;
}

export const drawerWidth = 225;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      top: "150px",
      [theme.breakpoints.up("sm")]: {
        top: "64px",
      }
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: '58px',
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(8) + 1,
      },
    },
  })
);

export const Sidebar = ({ isDrawerOpen }: Prop) => {
  const links: Array<SideLinksProps> = [
    {
      DisplayName: "Ops screen",
      Url: "/",
      ImageUrl: <DashboardIcon />,
    },
    {
      DisplayName: "Sales screen",
      Url: "/salesscreen/",
      ImageUrl: <DashboardIcon />,
    },
    {
      DisplayName: "Contracts",
      Url: "/contracts/",
      ImageUrl: <LibraryBooksIcon />,
    },
    {
      DisplayName: "Buyers",
      Url: "/buyers/",
      ImageUrl: <PersonIcon />,
    },
    {
      DisplayName: "Buyer Groups",
      Url: "/buyergroups/",
      ImageUrl: <PeopleAltIcon />,
    },
    {
      DisplayName: "User Management",
      Url: "/users/",
      ImageUrl: <AccountCircleIcon />,
    },
    /* {
      DisplayName: "Error Simulator",
      Url: "/errorsimulator/",
      ImageUrl: <InboxIcon />,
    }, */
    {
      DisplayName: "Transform Code",
      Url: "/transformcode/",
      ImageUrl: <TransformIcon />,
    },
    {
      DisplayName: "Constants",
      Url: "/reqconstants/",
      ImageUrl: <DonutSmallIcon />,
    },
    {
      DisplayName: "Leads Search",
      Url: "/leads/",
      ImageUrl: <SearchIcon />,
    },
    {
      DisplayName: "Reports",
      Url: "/reports/",
      ImageUrl: <DescriptionIcon />,
    },
    {
      DisplayName: "Lead Field Data",
      Url: "/leadfielddata/",
      ImageUrl: <PlaylistAddCheckIcon />,
    },
    {
      DisplayName: "General Settings",
      Url: "/general-settings/",
      ImageUrl: <SettingsIcon />,
    },
  ];

  const ListItemLink = (props: ListItemProps<"a", { button?: true }>) => {
    return <ListItem button component="a" {...props} />;
  };

  const classes = useStyles();
  const location = useLocation();

  /* useEffect(()=>{
    console.log('location.pathname', location.pathname);
  },[location]) */

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={!!isDrawerOpen}
      // classes={{ paper: classes.drawerPaper }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >
      <List className={classes.drawerPaper}>
        {links.map(({ DisplayName, Url, ImageUrl }, i) => (
          <ListItemLink href={Url || "#"} key={i} selected={!!Url && location.pathname == Url || Url !== "/" && location.pathname.startsWith(Url) }>
            <ListItemIcon>{ImageUrl}</ListItemIcon>
            <ListItemText primary={DisplayName} />
          </ListItemLink>
        ))}
      </List>
    </Drawer>
  );
};
