import { DateTime } from "luxon";
import { getTimezoneSetting, LDPAPIDateFormat } from "./date";

const timezone = getTimezoneSetting();

export const isEDW = (startDate: Date): boolean => {

  const startDateTzFmt = DateTime.fromJSDate(startDate).toFormat(LDPAPIDateFormat);
  const edwDaysMargin = Number(process.env.FETCH_EDW_DAYS_CONDITION) || 7;
  const odsStartDateTzFmt = DateTime.now().setZone(timezone).minus({days: edwDaysMargin}).toFormat(LDPAPIDateFormat);

  return startDateTzFmt < odsStartDateTzFmt ? true : false;
};
