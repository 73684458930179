const capitalize = (s: string): string => {
  return s
    .replace(
      / ([a-zA-Z])(\w*)/gm,
      (_$0, $1, $2) => " " + $1.toUpperCase() + $2.toLowerCase()
    )
    .replace(
      /^([a-zA-Z])(\w*)/gm,
      (_$0, $1, $2) => $1.toUpperCase() + $2.toLowerCase()
    );
};

const toCamelCase = (input: string): string => {
  if (!input) return "";
  console.log(input);
  const capped = capitalize(input).replace(/ /g, "");
  return capped[0].toLowerCase() + capped.slice(1);
};

const createListUrl = (input: { [key: string]: string }): string => {
  return `${Object.keys(input).reduce((final, next): string => {
    return `${final}&$${next}=${input[next]}`;
  }, "")}`.substring(1);
};

const isEmpty = (v: string | null | undefined) => {
  return !v || v === null || v === undefined || !v.trim() || v.length === 0 || /^\s*$/.test(v);
}

const isNullOrUndefined = (v: string | null | undefined) => {
  return v === null || v === undefined;
}

export const convertString = {
  capitalize,
  toCamelCase,
  createListUrl,
  isEmpty,
  isNullOrUndefined,
};
