
import ReactGA from "react-ga";

interface Props {    
    name: string,
    caption: string,
    values: {}
}

export const EmailCharBlockers: any = { '@': '[at]', '.': '[dot]' };

ReactGA.initialize("UA-212275013-1", { debug: false });

export const pageTracker = () => {    
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export const eventTracker = ({ name, caption, values }: Props) => { 
    let meta = sessionStorage.getItem("GoogleUserMeta");
    if (meta) {
        ReactGA.event({
            category: name,
            action: caption,
            label: JSON.stringify(values)
        });   
    }        
}