import { flex } from "./flex";
import { color } from "./color";
import { convertColor } from "../common/utils";

const hover = {
  "&:hover": {
    cursor: "pointer"
  }
}

const base = {
  padding: 10,
  borderRadius: 10,
  border: "none",
  fontWeight: "bold",
  fontSize: "1.3em",
  ...flex.row,
  ...flex.center,
  ...hover
}

const primary = {
  backgroundColor: color.primary,
  color: color.white,
  "&:hover": {
    backgroundColor: convertColor.brighten(color.primary)
  }
}

const secondary = {
  backgroundColor: color.secondary,
  color: color.white,
  "&:hover": {
    backgroundColor: convertColor.brighten(color.secondary)
  }
}

const error = {
  backgroundColor: color.red,
  color: color.white,
  "&:hover": {
    backgroundColor: color.red   
  }
}

const success = {
  backgroundColor: color.green,
  color: color.white,
  "&:hover": {
    backgroundColor: color.green
  }
}

const none = {
  border: "none",
  background: "none"
}

export const button = {
  base,
  primary,
  secondary,
  error,
  success,
  none,
  hover,
};
