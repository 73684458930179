export const color = {
  primary: "#457373",
  secondary: "#888888",
  white: "#ffffff",
  black: "#000000",
  red: "#CC3939",
  blue: "#3C91E6",
  green: "#11912B",
  yellow: "#FFE74C",
  orange: "#F06449",
  cyan: "#25CED1",
  purple: "#390099",
  grey_l4: "#f8f8f8",
  grey_l3: "#d8d8d8",
  grey_l2: "#cccccc",
  grey_l1: "#aaaaaa",
  grey: "#888888",
  grey_d1: "#666666",
  grey_d2: "#444444",
  grey_d3: "#222222",
  transparent: "#0000",
};
