import iwanthue from 'iwanthue';

const rgbToHsl = (r: number, g: number, b: number) => {
  r = r /= 255;
  g = g /= 255;
  b = b /= 255;

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h: number = 0,
    s: number,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        h = s = 0;
    }

    h /= 6;
  }

  return [h, s, l];
};

const hslToRgb = (h: number, s: number, l: number) => {
  var r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
};

const brighten = (t: string, p: number = 0.1) => {
  const r = parseInt(t.substring(1, 3), 16);
  const g = parseInt(t.substring(3, 5), 16);
  const b = parseInt(t.substring(5, 7), 16);
  let [h, s, l] = rgbToHsl(r, g, b);
  l = Math.min(l + p, 1);
  let [x, y, z] = hslToRgb(h, s, l);
  return `rgb(${x.toFixed(0)},${y.toFixed(0)},${z.toFixed(0)})`;
};

const darken = (t: string, p: number = 0.1) => {
  const r = parseInt(t.substring(1, 3), 16);
  const g = parseInt(t.substring(3, 5), 16);
  const b = parseInt(t.substring(5, 7), 16);
  let [h, s, l] = rgbToHsl(r, g, b);
  l = Math.max(l - p, 0);
  let [x, y, z] = hslToRgb(h, s, l);
  return `rgb(${x.toFixed(0)},${y.toFixed(0)},${z.toFixed(0)})`;
};

const alpha = (s: string, a: number): string => {
  if (s.startsWith("#")) {
    s = brighten(s, 0);
  }
  return `rgba${s.slice(3, -1)},${a})`;
};

export const createPallete = (colors:number) => {
  return iwanthue(colors, {
    clustering: 'k-means',
    colorSpace: 'default',
    seed: 'LDPUI',
    quality: 100
  });
}

export const convertColor = {
  rgbToHsl,
  hslToRgb,
  brighten,
  darken,
  alpha,
};
