import React from "react";
import { styles } from "../styles";
import ReactLoading from "react-loading";
import { CircularProgress, Paper, Container } from "@material-ui/core";

export const ButtonLoading = () => (
  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    <ReactLoading
      type="spin"
      color={styles.color.white}
      height="25px"
      width="25px"
    />
  </div>
);

export const PageLoading = () => (
  <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
    <ReactLoading
      type="spin"
      color={styles.color.primary}
      height="30vh"
      width="25px"
    />
  </div>
);


interface ContentLoadingProps{
  message?: string;
  showTip?: boolean;
};

export const ContentLoading = ({message, showTip}:ContentLoadingProps ) => {

  const tips = [
    `Loading...`,
    `So far so good...`,
    `Let's hope nothing crash`,
    `Please wait!`,
  ];

  return (
    <Container maxWidth={false} style={Style.Wrapper}>
      <Container style={Style.ContentContainer}>
        <CircularProgress size={24} />
        { (message || showTip) && (
          <Container>
            <Paper style={Style.Message}>
              {/* {message ? message : tips[Math.floor(Math.random() * tips.length)]} */}
              {message ? message : tips[0]}
            </Paper>
          </Container>
        )}
      </Container>
    </Container>)
};

const Style:any = {
  Wrapper: {
    position: 'absolute', 
    zIndex: 110, 
    top: 0, 
    left: 0, 
    width: '100%',
    height: '100%', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    background: 'rgba(255,255,255,0.8)',
  },
  ContentContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  Message: {
    padding: '20px',  
    marginTop: '20px', 
    textAlign: 'center',
    display: 'inline-block',
  }
};