import { useStaticQuery, graphql } from "gatsby";
export const useSiteMetadata = () => {
   const siteData = useStaticQuery(graphql`
   query SiteTitleQuery {
     site {
       siteMetadata {
         title
       }
     }
   }
 `);
  return siteData.site.siteMetadata;
}