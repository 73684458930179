import React, { ReactNode, useState, useEffect } from "react";
import { makeStyles, Theme, createStyles, CssBaseline, Container } from "@material-ui/core";
import { MuiThemeProvider } from '@material-ui/core/styles';

import { isLoggedIn, isBrowser, getRefreshToken, logout, loginHref, getToken, refreshAuthentication } from "../common/utils/auth";
import { convertColor } from "../common/utils";
import { Header } from "./header";
import { Sidebar, drawerWidth } from "./sidebar";
/* import * as suits from "../images/suits.svg"; */
import { styles } from "../styles";
import { ContentLoading } from "./loading";
import clsx from "clsx";

import "react-datepicker/dist/react-datepicker.css";
import "../styles/layout.css";
import "../styles/react-datepicker.scss";


const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    view: {
      zIndex: 1,
      flexGrow: 1,
      height: '100vh',
      overflow: 'hidden auto',
      display: 'flex',
    },
    content: {
      // margin: 0,
      // transition: theme.transitions.create(['width', 'margin'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      /* [theme.breakpoints.up("lg")]: {
        "& > div": {
          paddingLeft: 56
        }
      } */
      display: 'flex',
      position: "relative",
    },
    contentShift: {
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    drawerOpen: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['width', 'margin-left'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      width: `calc(100% - 60px)`,
      marginLeft: '60px',
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - 70px)`,
        marginLeft: theme.spacing(8) + 1,
      },
      transition: theme.transitions.create(['width', 'margin-left'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    container: {
      padding: "0px",
      // paddingBottom: theme.spacing(4),
      marginTop: "150px",
      [theme.breakpoints.up("sm")]: {
        marginTop: "64px",
      },
      display:'flex',
      flexGrow: 1,
    },
    background: {
      position: "absolute",
      overflow: "hidden",
      width: "100%",
      height: "calc(100vh - 80px)",
      left: 0,
      /* top: 80, */
      zIndex: -1000,
      backgroundColor: `${convertColor.alpha(styles.color.primary, 0.1)}`,
      // maskImage: `url(${suits})`,
      maskSize: "300px 200px",
      maskRepeat: "repeat"
    },
  })
});

interface Props {
  children?: ReactNode;
}

export const Layout = ({ children }: Props) => {
  const classes = useStyles();

  const [isDrawerOpen, setDrawerOpen] = useState(true);
  const handleDrawer = () => {
    setDrawerOpen(! (isDrawerOpen === true));
  };

  const [pageLoading, setPageLoading] = useState(true);

  useEffect(()=>{
    //console.log("Layout check isLoggedIn", isLoggedIn());
    if (isBrowser && !isLoggedIn() && location?.pathname !== "/login/") {
      if(getRefreshToken()) {
        refreshAuthentication().then((data)=>{
          setPageLoading(false);
        });
      } else {
        logout(() => {
          document.location.href = loginHref;
        });
      }
    } else {
      setPageLoading(false);
    }
  }, []);

  useEffect(()=>{
    if(location?.pathname === "/login/"){
      setDrawerOpen(false);
    } else {
      setDrawerOpen(isLoggedIn());
    }
    
  }, [ isLoggedIn() ]);
  
  return (
    <MuiThemeProvider theme={styles.theme}>
      <CssBaseline />
      { pageLoading ? <ContentLoading message="Loading..." /> :  (
        <>
          <Header handleDrawer={handleDrawer} isDrawerOpen={isDrawerOpen} />  
          {isLoggedIn() && <Sidebar isDrawerOpen={isDrawerOpen} /> }

          <main className={classes.view}>
            <Container maxWidth={false} className={classes.container}>
              <div 
                className={`${classes.content} ${clsx({
                  [classes.drawerOpen]: isDrawerOpen,
                  [classes.drawerClose]: !isDrawerOpen,
                })}`}
              >{children}</div>
            </Container>
            {/* <div className={classes.background} /> */}
          </main>
        </>
      )}
    </MuiThemeProvider>
  );
};