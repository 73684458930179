import { createMuiTheme } from "@material-ui/core";

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    headerAvatarColor: {
      violet: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      },
      orange: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      },
      green: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      },
      rose: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      }
    }
  }
  interface ThemeOptions {
    headerAvatarColor: {
      violet: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      },
      orange: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      },
      green: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      },
      rose: {
        background: React.CSSProperties['background'],
        boxShadow: React.CSSProperties['boxShadow'],
      }
    }
  }
}

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

export const theme = createMuiTheme({
   palette: {
     primary: {
       light: '#c1e5e2',
       main: '#00838f',
       dark: '#005b64',
       contrastText: '#fff',
     },
     secondary: {
       light: '#bb6bc9',
       main: '#ab47bc',
       dark: '#773183',
       contrastText: '#000',
     },
     neutral: {
      main: '#E0F1EF',
     },
     warning: {
      light: '#ffac33',
      main: '#ff9800',
      dark: '#b26a00',
      contrastText: '#000',
     },
   },
   headerAvatarColor: {
      violet: {
        background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)",
      },
      green: {
        background: "linear-gradient(60deg, #66bb6a, #43a047)",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)",
      },
      orange: {
        background: "linear-gradient(60deg, #ffa726, #fb8c00)",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)",
      },
      rose: {
        background: "linear-gradient(60deg, #ec407a, #d81b60)",
        boxShadow:
          "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
      },
   },
   overrides: {
     MuiFormControl: {
      root:{
        '&&[hidden]': {
          display: 'none',
        }
      }
     },
     MuiPaper:{
       root:{
        // Sample override snippet for component defined class 'ContentPaper'
        //  '&&.ContentPaper': {
        //     border:'1px solid red',
        //  }
       }
     },
     MUIDataTable: {
       root: {
       },
       paper: {
         boxShadow: "none",
       }
     },
     MUIDataTableBodyRow: {
       root: {
         '&:nth-child(odd)': { 
           backgroundColor: '#E0F1EF'
         }
       }
     },
     MUIDataTableHeadCell: {
       root: {
         padding: "10px"
       }
     },
     MUIDataTableBodyCell: {
       root: {
         padding: "0px 5px",
         borderBottom: '0px solid rgba(0, 0, 0, 1)',
         
       }
     },
     MUIDataTableCell: {
      root: {
        borderBottom: '0px solid rgba(0, 0, 0, 1)',
      },
    },
    MuiTableCell: {
      root: {
        '&.MuiTableCell-paddingCheckbox': { 
          // display: "none",
        }
      }
    }
   }
 });