const display = "flex";

const column = {
  display,
  flexDirection: "column",
}

const row = {
  display,
  flexDirection: "row",
}

const center = {
  justifyContent: "center",
  alignItems: "center",
}

export const flex = {
  column,
  row,
  center,
};
