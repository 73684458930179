import zxcvbn from "zxcvbn";
import { NestDataObject } from "react-hook-form";
import { EmailPattern, ZipCodePattern } from "./regex";

const GetErrorMessage = (erroredInput: string, errors: any): string | null => {
  const error = errors[erroredInput];
  if (error) {
    switch (error.type) {
      case "maxLength":
      case "minLength":
        return error.ref.name + " doesn't meet length requirements";
      case "required":
        return error.ref.name + " is a required field";
      case "strongPassword":
        return "Your password is not strong enough";
      case "matchPassword":
        return "New passwords do not match";
      case "pattern": {
        switch (erroredInput) {
          case "Email":
            return "Please enter a valid email address.";
          case "ZipCode":
            return "Please enter a valid ZIP code";
        }
      }
    }
  }
  return null;
};

const strongPassword = (p: string): boolean => {
  const check = zxcvbn(p);
  console.log(
    `It would take ${check.crack_times_seconds.offline_fast_hashing_1e10_per_second} seconds to crack this`
  );
  if (check.crack_times_seconds.offline_fast_hashing_1e10_per_second < 100) {
    return false;
  } else {
    return true;
  }
};

const matchPassword = (newPass: string, confirm: string) => {
  console.log(newPass, confirm);
  if (newPass === confirm) {
    return true;
  } else return false;
};

export const formTools = {
  GetErrorMessage,
  EmailPattern,
  ZipCodePattern,
  strongPassword,
  matchPassword,
};
