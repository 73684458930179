export const flattenObject = (obj:any) => {
   const flattened:any = {};
 
   Object.keys(obj).forEach((key) => {
     if (typeof obj[key] === 'object' && obj[key] !== null) {
       Object.assign(flattened, flattenObject(obj[key]));
     } else {
       flattened[key] = obj[key];
     }
   });
 
   return flattened;
 }

 export const cloneObject = (object: unknown) => JSON.parse(JSON.stringify(object));