const ensureDouble = (input: number): string => {
  if (input < 10) return "0" + input;
  else return input.toString();
};

const convertMoney = (s: number, withCents: boolean = false): string => {
  return s
    ? "$" +
        s
          .toFixed(withCents ? 2 : 0)
          .toString()
          .replace(/(?!^.{4}$)(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : "N/A";
};

export const valueIsWithinRange = (rangeString:string, value:number) => {
  const absoluteValue = /^([0-9]{1,}(\.[0-9]+){0,1})+$/.test(rangeString);
  const rangedValue = /^([0-9]{1,}(\.[0-9]+){0,1})+\-([0-9]{1,}(\.[0-9]+){0,1})+$/.test(rangeString);
  const upwardsValue = /^([0-9]{1,}(\.[0-9]+){0,1})+\+$/.test(rangeString);
  const downwardValue = /^([0-9]{1,}(\.[0-9]+){0,1})+\-$/.test(rangeString);

  if(absoluteValue){
    return Number(rangeString) === value;
  } else if(rangedValue){
    const [lower, upper] = rangeString.split("-").map(strv => strv.trim());
    return value >= Number(lower) &&  value <= Number(upper);
  } else if(upwardsValue){
    return value >= Number(rangeString.replace("+", ""));
  } else if(downwardValue){
    return value <= Number(rangeString.replace("+", ""));
  } else {
    if(["0 / null", "0"].includes(rangeString)){
      return value === 0;
    } else if(["null", "empty", "undefined", "(empty value)", "(no value)"].includes(rangeString.toLowerCase())){
      return isNaN(value);
    }
    return false;
  }
}

export const convertNumber = {
  ensureDouble,
  convertMoney,
};
