import React, { useState, useEffect } from "react";
import { logout, isLoggedIn, loginHref } from "../common/utils/auth";
import { GoogleUserMeta } from "../common/utils/googleUserMeta";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { styles } from "../styles";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Avatar,
  Popover,
  Hidden,
} from "@material-ui/core";
import { useSiteMetadata } from "../common/hooks/useSiteMetaData";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import TimezoneSelector from "./timezone";
import { getTimezoneSetting } from "../common/utils/date";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GetVertical } from "../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../common/models/vertical";
import { GetSubVertical, GetSubVertical_LDPConfigQueryGroup_SubVertical } from "../common/models/types/GetSubVertical";
import { GetQueuesCount } from "../common/models/types/GetQueuesCount";
/* import { GET_QUEUES_COUNT } from "../common/models/queuesCount"; */
/* import NewsTicker from "./newsticker"; */
import { pageTracker } from "./tracker";
import {useDispatch} from "react-redux";
import { updateLoggedInUser } from "../state/loggedInUserReducer";
import { GET_LOGGEDIN_USER_CONFIG } from "../common/models/users";

const envThemeBackground = () => {
  return process.env.GATSBY_BRANCH !== "production"
    ? {
      background: `repeating-linear-gradient(135deg,${styles.color.primary},${styles.color.primary
        } 10px,${process.env.GATSBY_BRANCH === "development" ? "#a72" : "#692"
        } 10px,${process.env.GATSBY_BRANCH === "development" ? "#a72" : "#692"
        } 20px)`,
    }
    : undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: styles.color.primary,
      boxShadow: "none",
      ...envThemeBackground(),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      // flexGrow: 1,
    },
    newsfeed: {
      flexGrow: 1,
      position: "relative",
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '300px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },

    },
    avatar: {
      border: "#fff 2px solid",
      width: "30px",
      height: "30px",
    },
    paper: {
      minWidth: "300px",
      padding: theme.spacing(2),
      marginTop: "-5px",
    },
  })
);

const popOverStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      minWidth: "300px",
      padding: theme.spacing(2),
      marginTop: "-5px",
    },
  })
);

export const Header = ({ isDrawerOpen, handleDrawer}: any) => {

  const { title } = useSiteMetadata()

  const googleUserMeta = GoogleUserMeta();
  const {data: loggedinUserData} = useQuery(GET_LOGGEDIN_USER_CONFIG);
  
  const classes = useStyles();
  const popOverClasses = popOverStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElHelp, setAnchorElHelp] = useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMenuOpenHelp = Boolean(anchorElHelp);
  const isMenuSettingsOpen = Boolean(anchorElSettings);
  const [menuState, setMenuState] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if(loggedinUserData){
      dispatch(updateLoggedInUser(loggedinUserData?.LDPUserConfigQueryGroup?.LoggedinUserConfig[0]));
    }
  },[loggedinUserData]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>, action: string) => {
    if (action === "user") {
      setAnchorEl(event.currentTarget);
    } else if (action === "help") {
      setAnchorElHelp(event.currentTarget);
    }  else if (action === "settings") {
      setAnchorElSettings(event.currentTarget);
    }
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
    setAnchorElHelp(null);
    setAnchorElSettings(null);
    window && window.location.reload();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="primary-account-menu"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleProfileMenuClose}
      getContentAnchorEl={null}
    >
      {/* <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem> */}
      <MenuItem
        onClick={() => {
          logout(() => {
            document.location.href = loginHref;
          });
        }}
      >
        Logout
      </MenuItem>
      <Typography style={{ padding: "6px 16px" }}>
        Version {process.env.VERSION}
      </Typography>
    </Menu>
  );


  const renderMenuHelper = (
    <Menu
      anchorEl={anchorElHelp}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="primary-account-menu-help"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMenuOpenHelp}
      onClose={handleProfileMenuClose}
      getContentAnchorEl={null}
    >
      {/* <MenuItem onClick={handleProfileMenuClose}>Profile</MenuItem> */}
      <MenuItem
        onClick={() => {
          window.open(
            'https://suitedconnector.atlassian.net/wiki/spaces/LDP/pages/773390337/LDP+Response+Parsing+Rules+and+Tokens',
            '_blank'
          );
        }}
      >
        Response Mapping
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.open(
            'https://suitedconnector.atlassian.net/wiki/spaces/LDP/pages/751042561/LDP+Transform+Syntax',
            '_blank'
          );
        }}
      >
        Transform Syntax
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.open(
            'https://suitedconnector.atlassian.net/wiki/spaces/LDP/pages/753532957/LDP+Filtering',
            '_blank'
          );
        }}
      >
        LDP Filtering
      </MenuItem>
    </Menu>
  );

  const renderSettings = (
    <Popover 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorElSettings}
      id="settings-menu"
      open={isMenuSettingsOpen}
      onClose={handleProfileMenuClose}
      classes={popOverClasses}
    >
      <TimezoneSelector displayLocalTimezone/>
    </Popover>
  );

  // Page tracker
  useEffect(() => {   
    pageTracker();
  }, []); 

  return (
    <div>      
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {isLoggedIn() ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              className={classes.menuButton}
            >
              {(!isDrawerOpen) ? (<MenuIcon />) : (<ChevronLeftIcon />)}
            </IconButton>
          ) : ("")}

          <Typography variant="h6" className={classes.title} noWrap>
            {title}
          </Typography>

          {isLoggedIn() ? (<>
            <div className={classes.newsfeed}>
              {/* {subvertical && (<NewsTicker data={[useNewstickerSubvertical()]} />)} */}
            </div>
            
            <Hidden mdDown >
              <Typography variant="subtitle1" className={classes.title}>
                Preferred Timezone: { getTimezoneSetting() }
              </Typography>
            </Hidden>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={(e) => handleProfileMenuOpen(e, "user")}
                color="inherit"
              >
                <Avatar alt={googleUserMeta?.name} src={googleUserMeta?.imageUrl} className={classes.avatar} />
              </IconButton>
              <IconButton
                aria-label="menu helper"
                aria-controls="primary-account-menu-help"
                aria-haspopup="true"
                onClick={(e) => handleProfileMenuOpen(e, "help")}
                color="inherit"
              >
                <ContactSupportIcon />
              </IconButton>
              <IconButton
                aria-label="menu settings"
                aria-controls="settings-menu"
                aria-haspopup="true"
                onClick={(e) => handleProfileMenuOpen(e, "settings")}
                color="inherit"
              >
                <SettingsIcon />
              </IconButton>
            </div>
          </>) : ("")}
          {renderMenuHelper}
          {renderMenu}
          {renderSettings}
        </Toolbar>
      </AppBar>
    </div>
  );
};