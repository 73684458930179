import gql from "graphql-tag";

export const GET_USERS = gql`
    query GetUsers($where: String){
        LDPUserConfigQueryGroup {
            UserConfig(limit:9999, where: $where) {
                BuyerToken
                CreatedDate
                Email
                FirstName
                LastName
                ModifiedDate
                Phone
                Title
                UserConfigBuyer{
                    BuyerId
                    CreatedDate
                    ModifiedDate
                    UserConfigBuyerId
                    UserConfigId
                    UserId
                }
                UserConfigId
                UserConfigRole{
                    CreatedDate
                    ModifiedDate
                    Role{
                        BusinessSegmentId
                        CreatedDate
                        Description
                        ModifiedDate
                        Name
                        RoleId
                        UserId
                    }
                    RoleId
                    UserConfigId
                    UserConfigRoleId
                    UserId
                }
                UserId
            }
        }
    }
`;

export const GET_USER_ROLES = gql`
    query GetUserRoles($where: String){
        LDPUserConfigQueryGroup {
            Role(limit:9999, where: $where) {
              UserId
              RoleId
              Name
              Description
              BusinessSegmentId
              ModifiedDate
              CreatedDate
            }
          }
    }
`;

export const SAVE_USER_CONFIG = gql`
    mutation SaveUserConfig($userConfigData: AddUserConfigInput, $userId: String){
        LDPUserConfigMutationGroup {
            AddUserConfig(UserConfigInput : $userConfigData, UserId: $userId)
        }
    }
`;

export const EDIT_USER_CONFIG = gql`
    mutation EditUserConfig($userConfigData: AddUserConfigInput, $userConfigId: ID, $userId: String){
        LDPUserConfigMutationGroup {
            EditUserConfig(EditUserConfigInput : $userConfigData, UserConfigId: $userConfigId, UserId: $userId)
        }
    }
`;

export const ASSIGN_USER_ROLE = gql`
    mutation AssignUserRole($userRoleData: AssignRoleInput, $userId: String){
        LDPUserConfigMutationGroup {
            AssignRoles(AssignRolesInput : $userRoleData, UserId: $userId)
        }
    }
`;


export const GET_ACCOUNT_MANAGERS = gql`
    query GetAccountManagers($where: String){
        LDPUserConfigQueryGroup{
            UserConfig(limit:9999, where: $where){
                Email
                FirstName
                LastName
                UserId
                UserConfigRole {
                    RoleId
                    Role{
                        Name
                        RoleId
                        Description
                    }
                }
            }
        }
    }
`;

export const GET_LOGGEDIN_USER_CONFIG = gql`
    query LoggedinUserConfig {
        LDPUserConfigQueryGroup {
            LoggedinUserConfig {
                Email
                FirstName
                LastName
                UserId
                UserConfigRole {
                    RoleId
                    Role{
                        Name
                        RoleId
                        Description
                    }
                }
            }
        }
    }
`;

export const RM_USER_CONFIG = gql`
    mutation DeleteUserConfig($userConfigId: ID!, $userId: String){
        LDPUserConfigMutationGroup {
            DeleteUserConfig(UserConfigId : $userConfigId, UserId: $userId)
        }
    }
`;
