import { useEffect, useState } from "react";
import { isLoggedIn, getToken } from "./auth";
import { useQuery } from "@apollo/react-hooks";
import { GET_GOOGLE_USER_PROFILE_INFORMATION } from "../models/profileinformation";

export interface GoogleUserMetaProp {
    email: string;
    familyName: string;
    givenName: string;
    googleId: string;
    imageUrl: string;
    name: string;
}

export const GoogleUserMeta = () => {
  const idToken = getToken();

  let meta = sessionStorage.getItem("GoogleUserMeta");
  const [googleUserMetaData, setGoogleUserMetaData] = useState<GoogleUserMetaProp | undefined>(meta ? JSON.parse(meta) : undefined);

  const { data: googleUserData, refetch } = useQuery(GET_GOOGLE_USER_PROFILE_INFORMATION, {
    variables: { idToken }
  });
  
  useEffect(() => {    
    if(googleUserData) {          
      const googleUserMeta = {
        email: googleUserData.ProfileInformation?.GoogleId,
        familyName: googleUserData.ProfileInformation?.FamilyName,
        givenName: googleUserData.ProfileInformation?.GivenName,
        googleId: googleUserData.ProfileInformation?.GoogleId,
        imageUrl: googleUserData.ProfileInformation?.ImageURL,
        name: googleUserData.ProfileInformation?.GivenName
      }

      sessionStorage.setItem(
        "GoogleUserMeta",
        JSON.stringify(googleUserMeta)
      );

      setGoogleUserMetaData(googleUserMeta || undefined);
    }
  }, [googleUserData]);
  
  useEffect(()=> {    
    if (! googleUserMetaData) {      
      refetch()
    }                
  }, [googleUserMetaData]);
  
  return googleUserMetaData;
}