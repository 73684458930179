import gql from "graphql-tag";

export const GET_GOOGLE_USER_PROFILE_INFORMATION = gql`
  query ProfileInformation($idToken: String!) {
    ProfileInformation (ID_Token: $idToken) {
      FamilyName
      GivenName
      GoogleId
      ImageURL
    }
  }
`; 