import { Box, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { setTimezoneSetting, getTimezoneSetting, dateToPreferredTimezone, tzOptions, LDPUIDateFormat, ServerMomentTimezone, LDPAPIDateFormat } from '../common/utils/date';

const useStyles = makeStyles({
   TimezoneSelector: {
     minWidth: "300px",
     "& .MuiOutlinedInput-root": {
         backgroundColor: "#fff",
      }
   },
 });

interface TimezoneSelectorProps {
   displayLocalTimezone?: boolean;
   displaySelectedTimezoneDateTime?: boolean;
   displayLocalDateTimeUTC?: boolean;
}

const timeZoneOptions = process.env.NODE_ENV === "development" ? [ ...tzOptions, "Asia/Manila" ] : tzOptions;

const TimezoneSelector = ( { displayLocalTimezone, displaySelectedTimezoneDateTime, displayLocalDateTimeUTC }:TimezoneSelectorProps) => {
   const [timezone, setTimezone] = useState<any>('system');
   const classes = useStyles();

   useEffect(()=>{
      setTimezone(getTimezoneSetting());
   },[]);

   const handleChangeTimezone = (value: any ) => {
      setTimezoneSetting(value);
      setTimezone(value);
   };

   return (
      <React.Fragment>
         <Autocomplete
            id="timezone-setting"
            options={timeZoneOptions}
            getOptionLabel={option => option}
            value={timezone}
            onChange={(event: any, value: any ) => handleChangeTimezone(value)}
            renderInput={params => (
               <TextField
                  {...params}
                  name="TimezoneSetting"
                  label="Preferred Timezone"
                  variant="outlined"
                  fullWidth
                  margin="dense"
               />
            )}
            className={classes.TimezoneSelector}
         />
         {
            displayLocalTimezone && (
                  <div>
                     Local Timezone: { DateTime.local().zoneName }
                  </div>
            )
         }
         {
            displayLocalDateTimeUTC && (
                  <div>
                     Local UTC: { DateTime.local().setZone('UTC').toFormat(LDPUIDateFormat) }
                  </div>
            )
         }
         {
            displaySelectedTimezoneDateTime && (
                  <div>
                     Local to TZ: { dateToPreferredTimezone( DateTime.local().setZone(ServerMomentTimezone).toFormat(LDPAPIDateFormat) ) }
                  </div>
            )
         }
      </React.Fragment>
   )
};

export default TimezoneSelector;