import { DateTime } from "luxon";
import { convertNumber } from "./number";

export const LDPUISimpleDateFormat = "MMMM dd, yyyy z";
export const LDPUIDateFormat = "MMMM dd, yyyy hh:mm:ss a z";
export const ISODateFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const LDPAPIDateFormat = ISODateFormat; //"yyyy-MM-dd'T'HH:mm:ss";
export const ServerMomentTimezone = "UTC"; //"US/Pacific";

const toName: { [key: number]: string } = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

const toNameShort: { [key: number]: string } = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "June",
  6: "July",
  7: "Aug",
  8: "Sept",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const tzOptions = [
  "US/Pacific",
  "US/Mountain",
  "US/Central",
  "US/Eastern",
  "US/Alaska",
  "US/Hawaii",
];

const toColor = (d: string): string => {
  const n = new Date(d);

  const r = (r_: any) => Math.max(Math.min(255 - r_, 255), 0);
  const g = (r_: any) =>
    parseInt(Math.sqrt(Math.pow(255, 2) - Math.pow(r_, 2)).toString());
  const b = (b_: any) => 50;

  const x = (n as any) - (new Date() as any);
  const y = (x / 100000000).toFixed(0);
  const z = `rgb(${r(y)},${g(r(y))},${b(y)})`;
  return z;
};

const toLongDate = (input: string): string => {
  let newDate = new Date(Date.parse(input));
  return `${
    convertDate.toName[newDate.getMonth()]
  } ${convertNumber.ensureDouble(newDate.getDate())}, ${newDate.getFullYear()}`;
};

const toShortDate = (input: string): string => {
  let newDate = new Date(Date.parse(input));
  return `${
    convertDate.toNameShort[newDate.getMonth()]
  } ${convertNumber.ensureDouble(newDate.getDate())}, ${newDate.getFullYear()}`;
};

const toDateString = (input: string, time: boolean = true): string => {
  // let newDate = new Date(Date.parse(input) - new Date().getTimezoneOffset());
  let newDate = new Date(Date.parse(input));
  let newDateTime = `${convertNumber.ensureDouble(
    newDate.getMonth() + 1
  )}/${convertNumber.ensureDouble(newDate.getDate())}/${newDate.getFullYear()}`;
  let newTime = `${convertNumber.ensureDouble(
    newDate.getHours() === 0
      ? 12
      : newDate.getHours() > 12
      ? newDate.getHours() - 12
      : newDate.getHours()
  )}:${convertNumber.ensureDouble(newDate.getMinutes())} ${
    newDate.getHours() >= 12 ? "PM" : "AM"
  }`;
  return time ? newDateTime + " " + newTime : newDateTime;
};

export const getTimezoneSetting = () => {
  //let timezone = sessionStorage.getItem("LDPTimezone") || "US/Pacific" || moment.tz.guess();

  let timezone = "US/Pacific";

  try {
    timezone =
      (!!sessionStorage && sessionStorage.getItem("LDPTimezone")) || timezone;
  } catch (error) {
    //sessionStorage is undefined during SSR
  }

  try {
    timezone =
      (!!localStorage && localStorage.getItem("LDPTimezone")) || timezone;
  } catch (error) {
    //locaStorage is not available on SSR
  }

  return timezone;
};

export const setTimezoneSetting = (value: string) => {
  try {
    localStorage && localStorage.setItem("LDPTimezone", value);
  } catch (error) {}

  try {
    sessionStorage && sessionStorage.setItem("LDPTimezone", value);
  } catch (error) {}
};

/**
 * Keys are what luxon library understands;
 * Values are what the backend understands
 */
export const usTimezones: any = {
  "US/Pacific": "Pacific Standard Time",
  "US/Mountain": "Mountain Standard Time",
  "US/Central": "Central Standard Time",
  "US/Eastern": "Eastern Standard Time",
  "US/Alaska": "Alaskan Standard Time",
  "US/Hawaii": "Hawaiian Standard Time",
};

export const getTimezoneSettingLongDesc = () => {
  return (
    getTimezoneSetting() in usTimezones && usTimezones[getTimezoneSetting()]
  );
};

export const getUsTimezoneIANAByValue = (value: string): string => {
  const keys = Object.keys(usTimezones);
  for (const key of keys) {
    if (usTimezones[key] === value) {
      return key;
    }
  }
  return ServerMomentTimezone; // Return null if the value is not found
}

/**
 * Converts date ISO formatted string (interpreted as date within server's timezone),
 * into prefferred timezone.
 * @param date Server's datetime value.
 * @param format
 * @timezone IANA format (i.e. US/Pacific)
 * @returns
 */
export const dateToPreferredTimezone = (
  date: string,
  format: string = LDPUIDateFormat,
  timezone?: string,
) => {
  //LDPDate will always be UTC (+00)
  return DateTime.fromISO(date, { zone: timezone ?? ServerMomentTimezone })
    .setZone(getTimezoneSetting())
    .toFormat(format);
};

/**
 * Converts non-UTC date ISO formatted date into UTC equivalent.
 * If date param contains no zone information or offset,
 * it will assume that the zone is system.
 *
 * @param date Non-UTC date string to be converted to UTC value format.
 * @param format
 * @returns
 */
export const dateToUTC = (date: string, format: string = LDPUIDateFormat) => {
  return DateTime.fromISO(date).setZone("UTC").toFormat(format);
};

/**
 * Convert date object into DateTime.
 * If `timezone` is given, it will interpret the time with that timezone.
 * Otherwise, it will use system preferred timezone.
 * @param date
 * @param timezone
 * @returns
 */
export const dateToMomentTz = (date: Date, timezone?: string) => {
  const isoDate = DateTime.fromJSDate(date).toISO({ includeOffset: false });
  return DateTime.fromISO(isoDate, {
    zone: !timezone ? getTimezoneSetting() : timezone,
  });
};

export const withinPast = (
  unixMsOrDateTime: number | DateTime,
  duration: Duration | Object | number
) => {
  const subject =
    unixMsOrDateTime instanceof DateTime
      ? unixMsOrDateTime
      : DateTime.fromMillis(unixMsOrDateTime, { zone: "local" });
  const compareTo = DateTime.local().minus(duration);

  return subject > compareTo;
};

export const convertDate = {
  toName,
  toNameShort,
  toColor,
  toDateString,
  toShortDate,
  toLongDate,
};
